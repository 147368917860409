<template>
  <toggle-section
    :is-open="isOpen[getSectionName('experianreport', index)]"
    :section="getSectionName('experianreport', index)"
    @toggle="toggleTable"
    chevron-position="right"
  >
    <template #titleSection>
      <div class="flex items-center space-x-2">
        <img src="/images/experian.svg" class="w-6 h-6" loading="lazy" />
        <lf-h3>
          {{ $t("DEALS.LEXIS_NEXIS.KYB.EXPERIAN_BUSINESS_REPORT") }}
        </lf-h3>
      </div>
    </template>
    <template #content>
      <div class="space-y-5">
        <!-- UCC -->
        <toggle-section
          :is-open="isOpen[getSectionName('experianreport_ucc', index)]"
          :section="getSectionName('experianreport_ucc', index)"
          @toggle="toggleTable"
          :has-padding="false"
          chevron-position="right"
          class="rounded-lg"
        >
          <template #titleSection>
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.UCC_TOTAL") }}</lf-h3>
          </template>
          <template #content>
            <div class="flex flex-row flex-no-wrap">
              <div
                v-for="(ucc, key, uccIndex) in business
                  .ExperianBusinessReportSection?.ExperianBusinessReports
                  ?.ExperianBusinessReport?.[0].UCCTotals"
                :key="uccIndex"
                class="flex flex-1 flex-col p-5 space-y-2 border-r last:border-r-0"
              >
                <div>{{ camelCaseToWords(key) }}</div>
                <div class="text-headline text-xl font-semibold">
                  {{ ucc }}
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
        <!-- Derogatory -->
        <toggle-section
          :is-open="isOpen[getSectionName('experianreport_derog', index)]"
          :section="getSectionName('experianreport_derog', index)"
          @toggle="toggleTable"
          :has-padding="false"
          chevron-position="right"
          class="rounded-lg"
        >
          <template #titleSection>
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.DEROGATORY") }}</lf-h3>
          </template>
          <template #content>
            <div class="flex flex-row flex-no-wrap">
              <div
                v-for="(derog, key, derogIndex) in business
                  .ExperianBusinessReportSection?.ExperianBusinessReports
                  ?.ExperianBusinessReport?.[0].Derogatory"
                :key="derogIndex"
                class="flex flex-1 flex-col p-5 space-y-2 border-r last:border-r-0"
              >
                <div>{{ camelCaseToWords(key) }}</div>
                <div class="text-headline text-xl font-semibold">
                  {{ derog }}
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
        <!-- Lien Total -->
        <toggle-section
          :is-open="isOpen[getSectionName('experianreport_lien', index)]"
          :section="getSectionName('experianreport_lien', index)"
          @toggle="toggleTable"
          :has-padding="false"
          chevron-position="right"
          class="rounded-lg"
        >
          <template #titleSection>
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.LIEN_TOTAL") }}</lf-h3>
          </template>
          <template #content>
            <div class="flex flex-row flex-no-wrap">
              <div
                v-for="(lien, key, lienIndex) in business
                  .ExperianBusinessReportSection?.ExperianBusinessReports
                  ?.ExperianBusinessReport?.[0].LienTotals"
                :key="lienIndex"
                class="flex flex-1 flex-col p-5 space-y-2 border-r last:border-r-0"
              >
                <div>{{ camelCaseToWords(key) }}</div>
                <div class="text-headline text-xl font-semibold">
                  <icon-base
                    v-if="typeof lien === 'boolean'"
                    :icon="lien ? 'check-circle' : 'x-circle'"
                    :icon-color="lien ? UTIL_COLORS.SUCCESS : UTIL_COLORS.ERROR"
                  />
                  <span v-else>
                    {{ lien }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
        <!-- Business IDs -->
        <toggle-section
          :is-open="isOpen[getSectionName('experianreport_bizid', index)]"
          :section="getSectionName('experianreport_bizid', index)"
          @toggle="toggleTable"
          :has-padding="false"
          chevron-position="right"
          class="rounded-lg"
        >
          <template #titleSection>
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.BUSINESS_IDS") }}</lf-h3>
          </template>
          <template #content>
            <div class="p-5 bg-gray-100">
              <div
                class="grid grid-cols-2 grid-rows-4 gap-x-4 gap-y-2 grid-flow-col"
              >
                <div
                  v-for="(id, key, idIndex) in business
                    .ExperianBusinessReportSection?.ExperianBusinessReports
                    ?.ExperianBusinessReport?.[0].BusinessIds"
                  :key="idIndex"
                  class="grid grid-cols-2 gap-2"
                >
                  <div>
                    {{ camelCaseToWords(key) }}
                  </div>
                  <div class="text-right text-headline">{{ id }}</div>
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
        <!-- Judgment Total -->
        <toggle-section
          :is-open="isOpen[getSectionName('experianreport_judgment', index)]"
          :section="getSectionName('experianreport_judgment', index)"
          @toggle="toggleTable"
          :has-padding="false"
          chevron-position="right"
          class="rounded-lg"
        >
          <template #titleSection>
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.JUDGMENT_TOTAL") }}</lf-h3>
          </template>
          <template #content>
            <div class="flex flex-row flex-no-wrap">
              <div
                v-for="(judgment, judgmentIndex) in Object.entries(
                  business.ExperianBusinessReportSection
                    ?.ExperianBusinessReports?.ExperianBusinessReport?.[0]
                    .JudgmentTotals ?? {}
                ).slice(0, 4)"
                :key="judgmentIndex"
                class="flex flex-1 flex-col p-5 space-y-2 border-r last:border-r-0"
              >
                <div>{{ camelCaseToWords(judgment[0]) }}</div>
                <div class="text-headline text-xl font-semibold">
                  <icon-base
                    v-if="typeof judgment[1] === 'boolean'"
                    :icon="judgment[1] ? 'check-circle' : 'x-circle'"
                    :icon-color="
                      judgment[1] ? UTIL_COLORS.SUCCESS : UTIL_COLORS.ERROR
                    "
                  />
                  <span v-else>
                    {{ judgment[1] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="p-5 bg-gray-100">
              <div
                class="grid grid-cols-2 grid-rows-1 grid-flow-col gap-x-4 gap-y-2"
              >
                <div
                  v-for="(judgment, judgmentIndex) in Object.entries(
                    business.ExperianBusinessReportSection
                      ?.ExperianBusinessReports?.ExperianBusinessReport?.[0]
                      .JudgmentTotals ?? {}
                  ).slice(4)"
                  :key="judgmentIndex"
                  class="grid grid-cols-2"
                >
                  <div>
                    {{ camelCaseToWords(judgment[0]) }}
                  </div>
                  <div class="text-right text-headline">
                    {{ judgment[1] }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
        <!-- Bankruptcy Total -->
        <toggle-section
          :is-open="isOpen[getSectionName('experianreport_bankruptcy', index)]"
          :section="getSectionName('experianreport_bankruptcy', index)"
          @toggle="toggleTable"
          :has-padding="false"
          chevron-position="right"
          class="rounded-lg"
        >
          <template #titleSection>
            <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.KYB.BANKRUPTCY_TOTAL") }}</lf-h3>
          </template>
          <template #content>
            <div class="flex flex-row flex-no-wrap">
              <div
                v-for="(bankruptcy, bankruptcyIndex) in Object.entries(
                  business.ExperianBusinessReportSection
                    ?.ExperianBusinessReports?.ExperianBusinessReport?.[0]
                    .BankruptcyTotals ?? {}
                ).slice(0, 4)"
                :key="bankruptcyIndex"
                class="flex flex-1 flex-col p-5 space-y-2 border-r last:border-r-0"
              >
                <div>{{ camelCaseToWords(bankruptcy[0]) }}</div>
                <div class="text-headline text-xl font-semibold">
                  <icon-base
                    v-if="typeof bankruptcy[1] === 'boolean'"
                    :icon="bankruptcy[1] ? 'check-circle' : 'x-circle'"
                    :icon-color="
                      bankruptcy[1] ? UTIL_COLORS.SUCCESS : UTIL_COLORS.ERROR
                    "
                  />
                  <span v-else>
                    {{ bankruptcy[1] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="p-5 bg-gray-100">
              <div
                class="grid grid-cols-2 grid-rows-2 grid-flow-col gap-y-2 gap-x-4"
              >
                <div
                  v-for="(bankruptcy, bankruptcyIndex) in Object.entries(
                    business.ExperianBusinessReportSection
                      ?.ExperianBusinessReports?.ExperianBusinessReport?.[0]
                      .BankruptcyTotals ?? {}
                  ).slice(4)"
                  :key="bankruptcyIndex"
                  class="grid grid-cols-2"
                >
                  <div>
                    {{ camelCaseToWords(bankruptcy[0]) }}
                  </div>
                  <div class="text-right text-headline">
                    {{ bankruptcy[1] }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </toggle-section>
      </div>
    </template>
  </toggle-section>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import ToggleSection from "@/components/ToggleSection.vue";
import type { Business } from "@/models/commercialData/lexisnexis/KybReport";
import { getSectionName } from "@/helpers/lexisnexis";
import { camelCaseToWords } from "@/helpers/formatting";
import { UTIL_COLORS } from "@/helpers/constants";

defineProps({
  business: {
    type: Object as PropType<Business>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isOpen: {
    type: Object,
    required: true
  }
});

const emit = defineEmits<{
  (eventName: "toggle", key: string): void;
}>();
const toggleTable = (section: string) => {
  emit("toggle", section);
};
</script>
